

































import { defineComponent, ref, watch, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'MAirtable',
  props: {
    programDoc: {
      required: false,
      type: Object,
      default: () => ({})
    },
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    isDraft: {
      type: Boolean,
      default: false
    },
    isTemplate: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    userTypeValue: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const formEmbedUrl = ref('');

    watch(formEmbedUrl, newUrl => {
      emit('update:formEmbedUrl', newUrl);
    });

    const airtableAdk = computed(() => {
      return props.programDoc?.data?.adks?.find(adk => adk.name === 'Airtable') || {};
    });

    // Set the default value for formEmbedUrl from airtableAdk if it exists
    if (airtableAdk.value.formEmbedUrl) {
      formEmbedUrl.value = airtableAdk.value.formEmbedUrl;
    }

    return {
      formEmbedUrl
    };
  }
});
